import './App.css';
import React from 'react';
import NewComponent from './NewComponent';
import './css/bootstrap-grid.min.css';
import './css/bootstrap-reboot.min.css';
import './css/magnific-popup.css';
import './css/style.css';
import './css/select2.min.css';
import {
    BrowserRouter as Router,
} from "react-router-dom";

function App() {

  return (
    <div className="App">
      <Router>
         <NewComponent/>
      </Router>
    </div>
  );
}

export default App;
