import React from 'react';
import {
    Link,
    useParams
} from "react-router-dom";
import links from './links.json';

function Item() {
    const {id} = useParams();

    return (
        <>
            {/* main content */}
            <main className="main">
                <div className="container">
                    <div className="row row--grid">
                        {/* breadcrumb */}
                        <div className="col-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb__item">
                                    <Link to={'/'}>Home</Link>
                                </li>
                                <li className="breadcrumb__item breadcrumb__item--active">Item</li>
                            </ul>
                        </div>
                        {/* end breadcrumb */}
                        {/* title */}
                        <div className="col-12">
                            <div className="main__title main__title--page" style={{display: 'flex', justifyContent: 'center'}}>
                                <h1>{links[id - 1].name}</h1>
                            </div>
                        </div>
                        {/* end title */}
                    </div>
                    <div className="row">
                        {/* content */}
                        <div className="col-12 col-xl-8">
                            <div className="asset__item">
                                <a className="asset__img" href={`${process.env.PUBLIC_URL}/assets/img/cover/${id}.gif`}>
                                    <img src={`${process.env.PUBLIC_URL}/assets/img/cover/${id}.gif`} alt={links[id - 1].name}/>
                                </a>
                            </div>
                        </div>
                        {/* end content */}
                        {/* sidebar */}
                        <div className="col-12 col-xl-4">
                            <div className="asset__info">
                                <div className="asset__desc">
                                    <h2>Description</h2>
                                    <p>
                                    {links[id - 1].description}

                                    </p>
                                </div>
                                <ul className="asset__authors">
                                    <li>
                                        <span>Collection</span>
                                        <div className="asset__author ">
                                            <img src={`${process.env.PUBLIC_URL}/assets/img/avatars/profile.jpg`} alt='Avatar'/>
                                            <a href="https://solsea.io/collection/6192abae5c063c40b9da053b">@Crisp City</a>
                                        </div>
                                    </li>
                                </ul>
                                {/* actions */}
                                <div className="asset__btns">
                                    <a
                                        href={links[id - 1].link}
                                        className="asset__btn asset__btn--full asset__btn--clr"
                                    >
                                        Buy
                                    </a>
                                </div>
                                {/* end actions */}
                            </div>
                        </div>
                        {/* end sidebar */}
                    </div>
                </div>
            </main>
            {/* end main content */}
        </>
    );
}

export default Item;