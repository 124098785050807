import React from "react";
import Header from "./Header";
import {Route, Switch} from "react-router-dom";
import Home from "./Home";
import Footer from "./Footer";
import Item from "./Item";

export default class NewComponent extends React.Component {

    render() {
        return (
            <div>
                 <Header />
                <Switch>
                    <Route path="/item/:id">
                        <Item/>
                    </Route>
                    <Route path="/">
                        <Home/>
                    </Route>
                </Switch>
                <Footer />
            </div>
        );
    }
};