import React from 'react';
import logo from './img/logo.png';
import {Link} from "react-router-dom";

function Header() {

    return <header className="header">
        <div className="header__content">
            <div className="header__logo">
                <Link to={'/'}>
                    <img src={logo} alt="" />
                </Link>
            </div>
        </div>
    </header>;
}

export default Header;