import React, {useEffect, useState} from 'react';
import Card from "./Card";

function Home() {
    const [full, setFull] = useState(false);
    const [cards, setCards] = useState([]);

    useEffect(()  => {
        const newCards = [];
        for (let i = 1; i < 9; i++) {
            newCards.push(<Card id={i} key={i}/>);
        }
        setCards(newCards);
    }, []);

    useEffect(() => {
        if(!full) return;
        const newCards = [];
        for (let i = 1; i < 16; i++) {
            newCards.push(<Card id={i} key={i}/>);
        }
        setCards(newCards);
    }, [full])

    return (
        <main className="main">
            {/* home */}
            <div className="home">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="home__content home__content--center">
                                <h1 className="home__title">
                                </h1>
                                <p className="home__text"></p>
                                <div className="home__btns">
                                    <a href="https://solsea.io/collection/6192abae5c063c40b9da053b"
                                       className="home__btn home__btn--clr">Buy</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* end home */}
            <div className="container">
                {/* explore */}
                <section className="row row--grid">
                    {/* title */}
                    <div className="col-12">
                        <div className="main__title main__title--center">
                            <h2>All exclusive nobodies</h2>
                        </div>
                    </div>
                    {/* end title */}

                    {cards}
                    {!full && <div className="col-12">
                        <button className="main__load" type="button" onClick={() => setFull(true)}>Load more
                        </button>
                    </div>}


                </section>
                {/* end explore */}
            </div>
        </main>
    );
}

export default Home;