import React from 'react';
import {
    Link
} from "react-router-dom";
import links from './links.json';

export default class Card extends React.Component {

    render() {
        return (
            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                <div className="card">
                    <Link to={`/item/${this.props.id}`}>
                        <div className="card__cover">
                            <img src={`${process.env.PUBLIC_URL}/assets/img/cover/${this.props.id}.gif`} alt="Card cover" />
                        </div>
                    </Link>
                    <h3 className="card__title">
                        <Link to={`/item/${this.props.id}`}>
                            {links[this.props.id - 1].name}
                        </Link>
                    </h3>
                    <div className="card__author">
                        <img src={`${process.env.PUBLIC_URL}/assets/img/avatars/profile.jpg`} alt="Card author" />
                        <a href="https://solsea.io/collection/6192abae5c063c40b9da053b">@Crisp City</a>
                    </div>
                </div>
            </div>
        );
    }

}
